import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import axios from "axios";

const Footer = () => {
  const [data, setData] = useState({
    brandUrl: "",
    brandImagePath: "",
    twitterUrl: "",
    facebookUrl: "",
    instagramUrl: "",
    linkedinUrl: "",
  });

  const section2Links = [
    { href: "/howitworks", text: "How It Works" },
    { href: "/aboutus", text: "About Us" },
    { href: "/guarantee", text: "Guarantee" },
    { href: "/investor", text: "Investor" },
  ];

  const section3Links = [
    { href: "/compareus", text: "Compare Us" },
    { href: "/cancellationpolicies", text: "Cancellation Policies" },
    { href: "/termsofservice", text: "Terms of Service" },
    { href: "/repaircafe", text: "Repair Cafe" },
    { href: "/partners", text: "Partners" },
    { href: "/press", text: "Press" },
  ];

  useEffect(() => {
    axios
      .get("/api/footer")
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {});
  }, []);

  return (
    <section className="footer">
      <Container>
        <div className="row">
          <div className="col-12 col-sm-6 col-md-3">
            <a href={data.brandUrl} title="" target="_blank" rel="noreferrer">
              <img
                src={data.brandImagePath}
                alt=""
                width="130px"
                height="auto"
              />
            </a>
          </div>
          {[section2Links, section3Links].map((group, groupIndex) => (
            <div key={groupIndex} className="col-12 col-sm-6 col-md-3">
              <ul>
                {group.map((link, linkIndex) => (
                  <li key={linkIndex}>
                    <a href={link.href}>
                      <p className="footer-link-text">{link.text}</p>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
          <div className="col-12 col-sm-6 col-md-3 social-icons">
            <div>
              <a
                href={data.twitterUrl}
                title=""
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-twitter"></i>
              </a>
              <a
                href={data.facebookUrl}
                title=""
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-facebook-f"></i>
              </a>
              <a
                href={data.instagramUrl}
                title=""
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-instagram"></i>
              </a>
              <a
                href={data.linkedinUrl}
                title=""
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-linkedin-in"></i>
              </a>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Footer;
