// @ts-nocheck
import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import parse from "html-react-parser";
import { SmallLoadingSpinner } from "../components/common/Loaders";
import { Container, Col, Row } from "reactstrap";
import Footer from "../components/layout/Footer";
import { ExploreTopCategories } from "../components/home/ExploreTopCategories";
import LogoStrip from "../components/home/LogoStrip";



interface Media {
    system: { name: string };
    fields: { umbracoFile: { src: string } };
}

interface Content {
    system: { name: string };
    fields: {
        title1_1: string;
        body1_1: string;
        title1_2: string;
        body1_2: string;
        title1_3: string;
        body1_3: string;
        media1: Media[];
        title2: string;
        body2: string;
        media2: Media[];
        title3: string;
        body3: string;
        media3: Media[];
    };
}

export function HowItWorks() {
    const [content, setContent] = useState<Content | null>(null);
    const [loading, setLoading] = useState(false);

    const { REACT_APP_CMS_URL, REACT_APP_CMS_HOW_IT_WORKS } = process.env;

    const getContent = async () => {
        setLoading(true);
        try {
            const res = await axios.get(REACT_APP_CMS_URL + REACT_APP_CMS_HOW_IT_WORKS);
            setContent(res.data as Content);
        } catch (error) {
            console.error("Error fetching content: ", error);
        }
        setLoading(false);
    };

    const getImage = (media: Media) => {
        return REACT_APP_CMS_URL + media.fields.umbracoFile.src;
    };

    useEffect(() => {
        getContent();
    }, []);

    const ImageSlider = ({ media }: { media: Media[] }) => {
        const [currentIndex, setCurrentIndex] = useState(0);
        const intervalRef = useRef<NodeJS.Timeout | null>(null);

        // Automatically cycle through images every 1.8 seconds on desktop
        useEffect(() => {
            if (window.innerWidth > 768) {
                intervalRef.current = setInterval(() => {
                    setCurrentIndex((prevIndex) => (prevIndex + 1) % media.length);
                }, 1800);

                return () => clearInterval(intervalRef.current as NodeJS.Timeout);
            }
        }, [media.length]);

        // Enable swipe gestures on mobile
        const handleSwipe = (direction: "left" | "right") => {
            if (direction === "left") {
                setCurrentIndex((prevIndex) => (prevIndex + 1) % media.length);
            } else if (direction === "right") {
                setCurrentIndex((prevIndex) =>
                    prevIndex === 0 ? media.length - 1 : prevIndex - 1
                );
            }
        };

        return (
            <div
                className="image-slider-wrapper position-relative"
                onTouchStart={(e) => {
                    const startX = e.touches[0].clientX;
                    const handleTouchEnd = (e: TouchEvent) => {
                        const endX = e.changedTouches[0].clientX;
                        if (startX - endX > 50) handleSwipe("left");
                        if (startX - endX < -50) handleSwipe("right");
                        document.removeEventListener("touchend", handleTouchEnd);
                    };
                    document.addEventListener("touchend", handleTouchEnd);
                }}
            >
                <div className="image-slider">
                    {media.map((mediaItem, index) => (
                        <div
                            key={index}
                            className={`image-slide ${index === currentIndex ? "active" : "inactive"
                                }`}
                        >
                            <img
                                src={getImage(mediaItem)}
                                alt={mediaItem.system.name}
                                className="img-fluid"
                                loading="lazy"
                            />
                        </div>
                    ))}
                </div>

                {media.length > 1 && (
                    <div className="slider-dots">
                        {media.map((_, index) => (
                            <span
                                key={index}
                                className={`dot ${index === currentIndex ? "active" : ""}`}
                                onClick={() => setCurrentIndex(index)}
                            ></span>
                        ))}
                    </div>
                )}
            </div>
        );
    };

    const renderMedia = (media: Media[]) => {
        if (!media) return null;
        return media?.length > 1 ? (
            <ImageSlider media={media} />
        ) : (
            <img
                alt={media[0]?.system.name}
                src={getImage(media[0])}
                className="img-fluid"
            />
        );
    };

    return loading || !content ? (
        <div className="spinner-container">
            <SmallLoadingSpinner />
        </div>
    ) : (
        <Container className="mt-2 static-pages-wrapper">
            <Row>
                <Col xs={12}>
                    <h1 className="text-center">{content.system.name}</h1>
                </Col>
            </Row>

            {/* Section 1 */}
            <Row className="section">
                <Col
                    xs={12}
                    md={6}
                    className="d-flex flex-column justify-content-center align-items-start order-2 order-md-1"
                >
                    <h3>{content.fields.title1_1}</h3>
                    {parse(content.fields.body1_1 || "")}
                    <h3>{content.fields.title1_2}</h3>
                    {parse(content.fields.body1_2 || "")}
                    <h3>{content.fields.title1_3}</h3>
                    {parse(content.fields.body1_3 || "")}
                </Col>
                <Col
                    xs={12}
                    md={6}
                    className="d-flex justify-content-center align-items-center order-1 order-md-2"
                >
                    {renderMedia(content.fields.media1)}
                </Col>
            </Row>

            <>
                <ExploreTopCategories />
                <LogoStrip />
                <Footer />
            </>

        </Container>
    );
}