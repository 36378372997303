import * as React from "react";
import { useEffect, useState, useRef } from "react";

import { sendAddToCartEvent } from "../../api/analytics";
import { toBookingConfirmationUrl } from "../terms/TermsRedirect";
import BasicModal from "../common/BasicModal";
import MarkdownModal from "../modal/MarkdownModal";
import { useUserContext } from "../../context/UserContext";

import {
  flexibleCancellationDescription,
  moderateCancellationDescription,
  strictCancellationDescription,
} from "../item/ItemListingConstantsAndDefaults";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCircleCheck, faTags } from "@fortawesome/free-solid-svg-icons";
import DatePickerAndPriceView from "./DatePickerAndPriceView";
import { Button } from "reactstrap";
import { useWindowSize, MAIN_BREAKPOINT } from "../../utils/WindowSize";
import { useScrollPosition } from "../../utils/ScrollPosition";
import { useTranslation } from "react-i18next";
export default function BookingArea({ itemDetails, calendarRef }) {
  const [bookingDetails, setBookingDetails] = useState(null);
  const [cancellationPolicyModalOpen, setCancellationPolicyModalOpen] =
    useState(false);

  const [insuranceModalOpen, setInsuranceModalOpen] = useState(false);
  const [preventFloating, setPreventFloating] = useState(false);

  const { user: currentlyLoggedInUser } = useUserContext();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const bookingArea = useRef();
  const buttonDisabled = !bookingDetails || bookingDetails.price === null;
  const hasInsurancePolicy = itemDetails?.insurancePlans?.length > 0;

  const scrollPos = useScrollPosition();
  const { width, height } = useWindowSize();
  const isMobileView = width < MAIN_BREAKPOINT; // Adjust this breakpoint value as needed
  const goToLink = (e) => {
    e.preventDefault();
    window.open(itemDetails.externalLink);
  };

  const confirmBooking = () => {
    sendAddToCartEvent(
      currentlyLoggedInUser.id,
      itemDetails.id,
      itemDetails.itemValue
    );
    navigate(
      toBookingConfirmationUrl(
        `?id=` + itemDetails.id,
        bookingDetails.startDate,
        bookingDetails.endDate
      )
    );
  };

  const returnCorrectCancellationBody = (cancellationPolicy) => {
    if (cancellationPolicy === "Flexible") {
      return flexibleCancellationDescription();
    }
    if (cancellationPolicy === "Moderate") {
      return moderateCancellationDescription();
    }
    if (cancellationPolicy === "Strict") {
      return strictCancellationDescription();
    }
    return <></>;
  };

  const getMultidayDiscounts = () => {
    if (!itemDetails) return [];
    const period = itemDetails?.priceInfo?.periods[0];
    if (!period || !period.dailyMultiBookings) return [];
    return period.dailyMultiBookings;
  };

  const setBookingData = (obj) => {
    if (!obj) {
      setBookingDetails(null);
      return;
    }
    const { startDate, endDate, priceObj } = obj;
    setBookingDetails({
      startDate,
      endDate,
      price: priceObj,
    });
  };

  const openCancellationPolicyModal = (e) => {
    e.preventDefault();
    setCancellationPolicyModalOpen(true);
  };

  const openInsuranceModal = (e) => {
    e.preventDefault();
    setInsuranceModalOpen(true);
  };

  const scrollToCalendar = () => {
    if (!calendarRef.current) return;
    if (!isMobileView) return;
    calendarRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  useEffect(() => {
    if (!bookingArea.current) return () => {};
    const preventFloat = bookingArea.current.offsetHeight + 20 > height;
    setPreventFloating(preventFloat || isMobileView);
  }, [bookingArea, height, isMobileView]);

  const wrapperStyle = preventFloating
    ? {}
    : { marginTop: `${scrollPos + 20}px` };

  return itemDetails.externalLink ? (
    <div className="booking-area" style={wrapperStyle} ref={bookingArea}>
      <h3>{t("book_directly")}</h3>
      <p className="small-text">
        This booking is handled directly with {itemDetails.user.name}
      </p>
      <Button onClick={goToLink} disabled={false} className="book-btn">
        {t("book_now")}
      </Button>
    </div>
  ) : (
    <div className="booking-area" style={wrapperStyle} ref={bookingArea}>
      <h3>{t("choose_booking_dates")}</h3>

      {getMultidayDiscounts().map((discount) => {
        return discount.discount === 0 ? null : (
          <div className="inner-row">
            <div className="row-left">
              <div className="svg-wrapper green">
                <FontAwesomeIcon icon={faTags} />
              </div>
            </div>
            <div className="content-right">
              <h2>
                {discount.discount}% {t("discount")}
              </h2>
              <p>
                {t("when_you_book_for_days", {
                  count: discount.amount,
                })}
              </p>
            </div>
          </div>
        );
      })}

      <DatePickerAndPriceView
        itemDetails={itemDetails}
        scrollToView={scrollToCalendar}
        setBookingData={setBookingData}
      />

      <Button
        onClick={buttonDisabled ? scrollToCalendar : confirmBooking}
        disabled={buttonDisabled}
        className="book-btn"
      >
        {t("book_now")}
      </Button>
      <div className="calendar-scroll-anchor" ref={calendarRef}></div>

      <div className="policy-documents">
        <a
          href="#_"
          className="general-more-link"
          onClick={(e) => openCancellationPolicyModal(e)}
        >
          <span>{t("item_detailed_cancellation_policy")}</span>
          <FontAwesomeIcon icon={faFileCircleCheck} />
        </a>
        {hasInsurancePolicy && (
          <>
            <a
              href="#_"
              className="general-more-link"
              onClick={(e) => openInsuranceModal(e)}
            >
              <span>{t("insurance_docs")}</span>
              <FontAwesomeIcon icon={faFileCircleCheck} />
            </a>
            <p className="small-text">
              ProtectMy Ltd is an Appointed Representative of Innovative Risk
              Labs Ltd Authorised and Regulated by the Financial Conduct
              Authority FRN 609155
            </p>
          </>
        )}
      </div>

      <BasicModal
        headerClass={""}
        modalTitle={"Cancellation Policy"}
        modalBody={returnCorrectCancellationBody(
          itemDetails.cancellationPolicy
        )}
        modalState={cancellationPolicyModalOpen}
        toggleModal={() => setCancellationPolicyModalOpen(false)}
      />

      <MarkdownModal
        modalState={insuranceModalOpen}
        toggleModal={() => setInsuranceModalOpen(false)}
        headerClass={""}
        markdownFile="/assets/documents/insurance/policy.markdown"
      />
    </div>
  );
}
