import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuildingShield,
  faLock,
  faTriangleExclamation,
  faUserShield,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
export function InsuranceView({ itemDetails }) {
  const { t } = useTranslation();

  const hasPlans =
    itemDetails.insurancePlans && itemDetails.insurancePlans.length > 0;

  const hasActivePlan =
    hasPlans && itemDetails.insurancePlans.some((plan) => plan.active);

  const optionalInsuranceQuote = itemDetails.cheapestInsurance?.fromPremium
    ? `£${itemDetails.cheapestInsurance.fromPremium.toFixed(2)}`
    : "";

  return itemDetails.requireInsurance ? (
    <div className="inner-row">
      <div className="row-left">
        <div className="svg-wrapper red">
          <FontAwesomeIcon icon={faLock} />
        </div>
      </div>
      <div className="content-right">
        <h2>{t("insurance_required")}</h2>
        <p>{t("add_insurance_to_rental")}</p>
      </div>
    </div>
  ) : itemDetails.externalLink ? (
    <div className="inner-row">
      <div className="row-left">
        <div className="svg-wrapper red">
          <FontAwesomeIcon icon={faBuildingShield} />
        </div>
      </div>
      <div className="content-right">
        <h2>{t("external_insurance_available")}</h2>
        <p>{t("external_can_be_covered")}</p>
      </div>
    </div>
  ) : hasActivePlan ? (
    <div className="inner-row">
      <div className="row-left">
        <div className="svg-wrapper red">
          <FontAwesomeIcon icon={faUserShield} />
        </div>
      </div>
      <div className="content-right">
        <h2>{t("optional_insurance_available")}</h2>
        <p>
          {t("insure_rental_from")} {optionalInsuranceQuote}
        </p>
      </div>
    </div>
  ) : hasPlans ? (
    <div className="inner-row">
      <div className="row-left">
        <div className="svg-wrapper red">
          <FontAwesomeIcon icon={faTriangleExclamation} />
        </div>
      </div>
      <div className="content-right">
        <h2>{t("insurance_unavailable")}</h2>
        <p>{t("insurance_unavailable_text")}</p>
      </div>
    </div>
  ) : (
    <div className="inner-row">
      <div className="row-left">
        <div className="svg-wrapper green">
          <FontAwesomeIcon icon={faLock} />
        </div>
      </div>
      <div className="content-right">
        <h2>{t("item_detailed_insurance_guarantee")}</h2>
        <p>{t("item_detailed_insurance_guarantee_title")}</p>
      </div>
    </div>
  );
}
