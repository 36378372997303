import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import { faGlobe, faUserCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

import {
  IConfig,
  IRentMyUserDetailedDto,
  ItemClient,
  ItemDto,
  ItemDtoPaginationDto,
  RentMyUserPublicDetailedDto,
  UserClient,
} from "../api/rentMyApi";
import { faXmarkCircle } from "@fortawesome/free-regular-svg-icons";
import { useEnv } from "../context/env.context";
import ItemHighlightOld from "../components/item/ItemHighlightOld";
import {
  faFacebook,
  faInstagram,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { LoadingFullScreen } from "../components/common/LoadingFullScreen";
import { ReviewList } from "../components/review/ReviewList";
import StarRating from "../components/common/StarRating";
import { toast } from "react-toastify";
import { SmallLoadingSpinner } from "../components/common/Loaders";

export function PublicProfilePage() {
  const searchParams = new URLSearchParams(window.location.search);

  const [userData, setUserData] = useState<IRentMyUserDetailedDto | undefined>(
    undefined
  );
  const [showReviews, setShowReviews] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(true);
  const [noProfileWithThatId, setNoProfileWithThatId] =
    useState<boolean>(false);
  const [itemHighlights, setItemHighlights] = useState<ItemDto[]>([]);
    const [itemsWithReviews, setItemsWithReviews] = useState<ItemDto[]>([]);

    const [pageSize, setPageSize] = useState(15);
    const [isFetching, setIsFetching] = useState(false);
    const [hasMore, setHasMore] = useState(true);

    const [totalDataCount, setTotalDataCount] = useState(0);

  const [userClient] = useState<UserClient>(
    new UserClient(new IConfig("notoken"), process.env.REACT_APP_API_ENDPOINT)
  );
  const [itemClient] = useState<ItemClient>(
    new ItemClient(new IConfig("notoken"), process.env.REACT_APP_API_ENDPOINT)
  );

  const { t } = useTranslation();
  const { apiServerUrl } = useEnv();

  const idConst = "id";
    const publicUserId: string = searchParams.get(idConst)!;

  const socialMediaUserNamePrefix = "@";
  const instagramUrl = "https://www.instagram.com/";
  const facebookUrl = "https://www.facebook.com/";
  const twitterUrl = "https://twitter.com/";
    const youtubeUrl = "https://www.youtube.com/c/";

    const handleScroll = useCallback(() => {
        const { scrollTop, clientHeight, scrollHeight } = document.documentElement;

        if (hasMore) {
            if (scrollTop + clientHeight >= scrollHeight - 20 && !isFetching) {
                setIsFetching(true);
                setPageSize(prevSize => prevSize + 9);
            }
        }
    }, [isFetching]);

  useEffect(() => {
    const populateUserData = async () => {
      if (publicUserId) {
        try {
          const response: RentMyUserPublicDetailedDto =
            await userClient.detailed52(publicUserId);
          setUserData(response as unknown as IRentMyUserDetailedDto);
          await populateUserListings(); // Await the populateUserListings function
        } catch (e: any) {
          if (e.status === 404) {
            setNoProfileWithThatId(true);
          }
          setIsProcessing(false); // Set isProcessing to false in case of error
          return;
        }
      } else {
        setNoProfileWithThatId(true);
        setIsProcessing(false); // Set isProcessing to false if no id is present
      }
    };
      populateUserData();
  }, [pageSize]);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll, true);

        return () => {
            window.removeEventListener("scroll", handleScroll, true);
        };
    }, [handleScroll]);

  const populateUserListings = async () => {
    if (!publicUserId) {
      return;
    }

    try {
      const itemResponse: ItemDtoPaginationDto = await itemClient.detailed25(
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        publicUserId,
        undefined,
          undefined,
          pageSize,
        1
      );
      if (itemResponse.dataCount == 0 || !itemResponse.data) {
        return;
        }

        setTotalDataCount(itemResponse.totalDataCount);
        setHasMore(itemResponse.nextPage != null);
      setItemHighlights(itemResponse.data);
      const reviewedItems = itemResponse.data.filter(
        (item) => item.reviewCount > 0
      );
      setItemsWithReviews(reviewedItems);
    } catch (error: any) {
      toast.error(t("item_listing_other_listing_loading_error"));
      console.error(
        "other items listed by the user can't be retrieved: " + error.response
      );
    } finally {
      setIsFetching(false);
      setIsProcessing(false); // Set isProcessing to false after items have been fetched or in case of error
    }
  };

  const profileSection = () => {
    return userData ? (
      <Card>
        <CardBody className="account-profile-main">
          <div>
            <img
              className="account_profile_profile_picture"
              alt={"profile picture of " + userData.name}
              src={`${
                userData?.profileImage
                  ? userData.profileImage.compressedPath
                  : "assets/img/profile-pic-placeholder.webp"
              }`}
            />
          </div>
          <br />
          <div className="account_profile_profile_info">
            <p className="mb-1">
              {userData.isVerified && (
                <span>
                  <FontAwesomeIcon icon={faUserCheck} />{" "}
                  {t("my_profile_id_verified")}
                </span>
              )}
              {!userData.isVerified && (
                <span>
                  {" "}
                  <FontAwesomeIcon icon={faXmarkCircle} />{" "}
                  {t("my_profile_id_not_verified")}
                </span>
              )}
            </p>
            <h2>{userData?.name ? userData.name : "-"}</h2>
            <p className="semi-bold">
              {userData?.nationalityCountry ? userData.nationalityCountry : ""}{" "}
            </p>
            <p>
              {t("my_profile_joined")}{" "}
              {userData?.joinedDate
                ? moment(userData.joinedDate).format("DD MMM YYYY")
                : ""}
            </p>
            <StarRating
              overallAverage={
                userData.reviewOverallAverage
                  ? Math.round((userData.reviewOverallAverage ?? 5) * 10) / 10
                  : 5
              }
              reviewCount={userData.reviewCount ? userData.reviewCount : 0}
            />
            {userData.aboutMe && (
              <>
                <br />
                <h3>About</h3>
                <p>{userData.aboutMe}</p>
              </>
            )}
          </div>
        </CardBody>
      </Card>
    ) : (
      <div className="loading-spinner-small"></div>
    );
  };

  const prefixAtSymbolIfNotPresent = (text: string | undefined) => {
    if (!text) {
      return undefined;
    }

    return text.charAt(0) !== socialMediaUserNamePrefix ? "@" + text : text;
  };

  const userSocials = userData
    ? [
        { url: "", value: userData.website, icon: faGlobe },
        {
          url: instagramUrl,
          value: prefixAtSymbolIfNotPresent(userData.instagramUrl),
          icon: faInstagram,
        },
        { url: facebookUrl, value: userData.facebookUrl, icon: faFacebook },
        {
          url: twitterUrl,
          value: prefixAtSymbolIfNotPresent(userData.twitterUrl),
          icon: faTwitter,
        },
        { url: youtubeUrl, value: userData.youtubeUrl, icon: faYoutube },
      ]
    : [];

  const generateSocialUrl = (
    socialUrlPrefix: string,
    text: string | undefined
  ) => {
    if (text === "" || text === undefined) {
      return "";
    }

    text =
      text.charAt(0) === socialMediaUserNamePrefix ? text.substring(1) : text;

    return socialUrlPrefix + text;
  };

  const aboutSection = () => {
    if (userSocials.findIndex((social) => social.value) === -1) {
      return undefined;
    }

    return (
      <Card>
        <CardBody>
          <Row>
            {userSocials.map((social, i, userSocials) => {
              if (social.value) {
                return (
                  <Col key={"socials_" + i} xs={12}>
                    <div className="public-profile-socials clickable">
                      <FontAwesomeIcon icon={social.icon} />
                      <a
                        className="ml-2 item-detailed-semi-bold hoverable-underline"
                        href={generateSocialUrl(social.url, social.value)}
                      >
                        {social.value}
                      </a>
                    </div>
                    {i + 1 !== userSocials.length && (
                      <hr className="small-hr" />
                    )}
                  </Col>
                );
              }
            })}
          </Row>
        </CardBody>
      </Card>
    );
  };

  return isProcessing ? (
    <LoadingFullScreen />
  ) : (
    <>
      <img
        className="public-prof-img"
        src={
          userData?.bannerImage?.path
            ? userData.bannerImage.path
            : "assets/img/user-banner-img-placeholder.webp"
        }
        alt="This user's banner image"
      />
      <Container>
        {noProfileWithThatId && (
          <h1 className="center-text">No profile with that Id</h1>
        )}
        {!noProfileWithThatId && (
          <>
            <Row>
              <Col className="public-profile-left-section" lg={3}>
                {profileSection()}
                {/*{aboutSection()}*/}
              </Col>
              <Col className="mt-2" lg={9}>
                <div className="reviews-or-other-item-selector">
                  <h2
                    className={!showReviews ? "selected" : ""}
                    onClick={() => setShowReviews(false)}
                                      >
                   {t("listings")} ({totalDataCount})      
                  </h2>
                  <h2
                    className={showReviews ? "selected" : ""}
                    onClick={() => setShowReviews(true)}
                  >
                    {t("reviews")} ({userData!.reviewCount})
                  </h2>
                </div>

                <Card className={showReviews ? "" : "hidden"}>
                  <CardBody>
                    {itemsWithReviews.length > 0 ? (
                      itemsWithReviews.map(
                        (reviewedItem: ItemDto, index: number) => {
                          return (
                            <div key={reviewedItem.id}>
                              <ReviewList
                                hidden={!showReviews}
                                itemId={reviewedItem.id}
                                userId={publicUserId}
                                showProduct={true}
                              ></ReviewList>
                              {itemsWithReviews.length > 1 &&
                                index < itemsWithReviews.length - 1 && (
                                  <hr className={"short"} />
                                )}
                            </div>
                          );
                        }
                      )
                    ) : (
                      <ReviewList
                        hidden={!showReviews}
                        itemId={""}
                        userId={publicUserId}
                        showProduct={true}
                      ></ReviewList>
                    )}
                  </CardBody>
                </Card>
                {!showReviews && (
                  <Row className="no-row-inverse-margin row-gap">
                    {/*<FilteringOptions searchSortOptions={undefined} onSearchSortChange={undefined} categoriesDataForFilter={undefined} onCategoryFilterChanged={undefined} selectedCategoryAspects={undefined} setAspectModalState={undefined} aspectModalState={undefined}/>*/}
                    {isProcessing && <LoadingFullScreen />}
                    {itemHighlights &&
                      itemHighlights.map(
                        (itemHighlight: ItemDto, index: number) => {
                          return (
                            <Col
                              key={"item_highlight_container_" + index}
                              xs={12}
                              md={6}
                              xl={4}
                            >
                              <ItemHighlightOld item={itemHighlight} />
                            </Col>
                          );
                          })
                                          }
                                          {isFetching && <SmallLoadingSpinner />}
                    {!isProcessing &&
                      (!itemHighlights || !itemHighlights.length) && (
                        <Container>
                          <Card>
                            <CardBody>
                              <p className="auto-margin center-text font-grey">
                                This user does not have any items listed
                              </p>
                            </CardBody>
                          </Card>
                        </Container>
                      )}
                  </Row>
                )}
              </Col>
            </Row>
          </>
        )}
      </Container>
    </>
  );
}
