import axios from "axios";
import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import { SmallLoadingSpinner } from "../components/common/Loaders";
import { Container, Col, Row } from "reactstrap";
import Footer from "../components/layout/Footer";
import { ExploreTopCategories } from "../components/home/ExploreTopCategories";
import LogoStrip from "../components/home/LogoStrip";

interface Media {
  system: { name: string };
  fields: { umbracoFile: { src: string } };
}

interface Content {
  system: { name: string };
  fields: {
    header1: string;
    body1: string;
    media1: Media[];
    header2: string;
    body2: string;
    media2: Media[];
    header3_1: string;
    body3_1: string;
    media3_1: Media[];
    header3_2: string;
    body3_2: string;
    media3_2: Media[];
    header3_3: string;
    body3_3: string;
    media3_3: Media[];
    header4: string;
    body4: string;
    media4: Media[];
  };
}

export function RepairCafe() {
  const [content, setContent] = useState<Content | null>(null);
  const [loading, setLoading] = useState(false);

  const { REACT_APP_CMS_URL, REACT_APP_CMS_REPAIR_CAFE } = process.env;

  const getContent = async () => {
    setLoading(true);
    try {
      if (REACT_APP_CMS_URL && REACT_APP_CMS_REPAIR_CAFE) {
        const res = await axios.get(REACT_APP_CMS_URL + REACT_APP_CMS_REPAIR_CAFE);
        setContent(res.data as Content);
      } else {
        console.error("CMS URL or Repair Cafe endpoint is not defined");
      }
    } catch (error) {
      console.error("Error fetching content: ", error);
    }
    setLoading(false);
  };

  const getImage = (media: Media) => {
    return REACT_APP_CMS_URL + media.fields.umbracoFile.src;
  };

  useEffect(() => {
    getContent();
  }, []);

  const renderMedia = (media: Media[]) => {
    return media?.length > 1 ? (
      <div className="image-slider-wrapper position-relative">
        <div className="image-slider">
          {media.map((mediaItem, index) => (
            <div key={index} className="image-slide">
              <img
                src={getImage(mediaItem)}
                alt={mediaItem.system.name}
                className="img-fluid"
                loading="lazy"
              />
            </div>
          ))}
        </div>
      </div>
    ) : (
      <img
        alt={media[0]?.system.name}
        src={getImage(media[0])}
        className="img-fluid"
      />
    );
  };

  return loading || !content ? (
    <div className="spinner-container">
      <SmallLoadingSpinner />
    </div>
  ) : (
    <Container className="mt-2 static-pages-wrapper">
      <Row>
        <Col xs={12}>
          <h1 className="text-center">{content.system.name}</h1>
        </Col>
      </Row>

      {/* Section 1 */}
      <Row className="section">
        <Col xs={12}>
          <h3>{content.fields.header1}</h3>
          {parse(content.fields.body1 || "No content available")}
          {renderMedia(content.fields.media1)}
        </Col>
      </Row>

      {/* Section 2 */}
      <Row className="section align-items-center">
        <Col xs={12}>
          <h3>{content.fields.header2}</h3>
          {parse(content.fields.body2 || "No content available")}
          {renderMedia(content.fields.media2)}
        </Col>
      </Row>

      {/* Section 3 */}
      {[1, 2, 3].map((index) => (
        <Row key={index} className="section align-items-center">
          <Col xs={12}>
            <h3>{content.fields[`header3_${index}`]}</h3>
            {parse(content.fields[`body3_${index}`] || "No content available")}
            {renderMedia(content.fields[`media3_${index}`])}
          </Col>
        </Row>
      ))}

      {/* Section 4 */}
      <Row className="section align-items-center">
        <Col xs={12}>
          <h3>{content.fields.header4}</h3>
          {parse(content.fields.body4 || "No content available")}
          {renderMedia(content.fields.media4)}
        </Col>
      </Row>

      <ExploreTopCategories />
      <LogoStrip />
      <Footer />
    </Container>
  );
}