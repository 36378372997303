import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Button, Col, Container, Row } from "reactstrap";
import AccountPagesSidebar from "./AccountPagesSidebar";
import { useAuth0 } from "@auth0/auth0-react";

import {
  IConfig,
  ItemClient,
  ItemDtoPaginationDto,
  ItemOrderBy,
} from "../../api/rentMyApi";
import { useUserContext } from "../../context/UserContext";
import AccountItemList from "./AccountItemsList";
import { SmallLoadingSpinner } from "../common/Loaders";

export function AccountItems() {
  const pageSizeConst = 5;

  const [unpublishedItemPageSize, setUnpublishedItemPageSize] = useState<number>(5);


  const [whichPageViewingUpToPublished, setWhichPageViewingUpToPublished] = useState<number>(1);
  const [whichPageViewingUpToUnpublished, setWhichPageViewingUpToUnpublished] = useState<number>(1);


  const [sortPublished, setSortPublished] = useState("default");
  const [sortUnpublished, setSortUnpublished] = useState("default");

  const [itemClient, setItemClient] = useState<ItemClient>();

  const [publishedItems, setPublishedItems] = useState<ItemDtoPaginationDto>();
  const [unpublishedItems, setUnpublishedItems] =
    useState<ItemDtoPaginationDto>();


  const { t } = useTranslation();
  const { user } = useUserContext();

  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();

  useEffect(() => {
    async function initClient() {
      const token = await getAccessTokenSilently();
      const itemClient = new ItemClient(new IConfig(token), process.env.REACT_APP_API_ENDPOINT);
      setItemClient(itemClient);
    }

    if (!isLoading && isAuthenticated && !itemClient) {
      initClient();
    }
  }, []);

  const [publishedItemsLoaded, setPublishedItemsLoaded] = useState(false);
  const [unpublishedItemsLoaded, setUnpublishedItemsLoaded] = useState(false);

  const [showMorePublishedClicked, setShowMorePublishedClicked] =
    useState(false);
  const [showMoreUnpublishedClicked, setShowMoreUnpublishedClicked] =
    useState(false);

  useEffect(() => {
    const localUserId = user.id;

    async function loadPublishedItems() {
      if (itemClient) {
        setPublishedItemsLoaded(false);
        itemClient
          .detailed25(
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            sortPublished === "default"
              ? undefined
              : sortPublished === "asc"
              ? ItemOrderBy.DownAlphabetically
              : ItemOrderBy.Alphabetically,
            localUserId,
            undefined,
            undefined,
            pageSizeConst,
            whichPageViewingUpToPublished
          )
          .then((response) => {
            setPublishedItems(response);
          })
          .finally(() => {
            setPublishedItemsLoaded(true);
          })
          .catch(() => {
            toast.error("Failed to load listings");
            setPublishedItemsLoaded(true);
          });
      }
    }

    if (localUserId) {
      loadPublishedItems();
    }
  }, [itemClient, sortPublished]);

  async function loadUnpublishedItems() {
    if (itemClient) {
      setUnpublishedItemsLoaded(false);
      itemClient
          .detailed26(
              true,
              true,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              sortUnpublished === "default"
                  ? undefined
                  : sortUnpublished === "asc"
                      ? ItemOrderBy.DownAlphabetically
                      : ItemOrderBy.Alphabetically,
              user.id,
              true,
              true,
              undefined,
              undefined,
              unpublishedItemPageSize,
              1
          )
          .then((response) => {
            setUnpublishedItems(response);
          })
          .finally(() => {
            setUnpublishedItemsLoaded(true);
            setWhichPageViewingUpToUnpublished(whichPageViewingUpToUnpublished + 1);
            setUnpublishedItemPageSize(unpublishedItemPageSize + 5);
          })
          .catch(() => {
            toast.error("Failed to load listings");
            setUnpublishedItemsLoaded(true);
          });
    }
  }


  useEffect(() => {
    if (user.id) {
      loadUnpublishedItems();
    }
  }, [itemClient, sortUnpublished]);

  const onLoadMoreUnpublished = useCallback(async () => {
    setShowMoreUnpublishedClicked(true);
    loadUnpublishedItems();
    // try {
    //   if (itemClient) {
    //     const res = await itemClient.detailed26(
    //         true,
    //         true,
    //         undefined,
    //         undefined,
    //         undefined,
    //         undefined,
    //         undefined,
    //         undefined,
    //         undefined,
    //         undefined,
    //         undefined,
    //         undefined,
    //         undefined,
    //         undefined,
    //         undefined,
    //         undefined,
    //         undefined,
    //         undefined,
    //         undefined,
    //         sortUnpublished === "default" ? undefined : sortUnpublished === "asc" ? ItemOrderBy.DownAlphabetically : ItemOrderBy.Alphabetically,
    //         user.id,
    //         true,
    //         true,
    //         undefined,
    //         undefined,
    //         pageSizeConst,
    //         whichPageViewingUpToUnpublished + 1,
    //         undefined
    //     )
    //     console.log("Unpublished Items")
    //     console.log(res)
    //
    //
    //     setUnpublishedItems({
    //       ...res,
    //       data:  [
    //         ...(unpublishedItems!.data! || []),
    //         ...(res.data || [])
    //       ]
    //     }
    //     );
    //   }
    //
    //   setWhichPageViewingUpToUnpublished(whichPageViewingUpToPublished + 1);
    // } catch (error) {
    //   toast.error("Error loading listings. Try again later.");
    // } finally {
    //   setShowMoreUnpublishedClicked(false);
    // }
    // setUnpublishedItemsLoaded(false)
  }, [unpublishedItems]);

  const onLoadMorePublished = useCallback(async () => {
    setShowMorePublishedClicked(true);

    try {
      if (itemClient) {
        const res = await itemClient.detailed25(
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            sortPublished === "default"
                ? undefined
                : sortPublished === "asc"
                    ? ItemOrderBy.DownAlphabetically
                    : ItemOrderBy.Alphabetically,
            user.id,
            undefined,
            undefined,
            pageSizeConst,
            whichPageViewingUpToPublished + 1
        )

        // @ts-ignore
        setPublishedItems({
              ...res,
              data:  [
                ...(publishedItems!.data! || []),
                ...(res.data || [])
              ]
            }
        );
      }

      setWhichPageViewingUpToPublished(whichPageViewingUpToPublished + 1);
    } catch (error) {
      toast.error("Error loading listings. Try again later.");
    } finally {
      setShowMorePublishedClicked(false);
    }
  }, [publishedItems]);

  const enableInsurance =
    process.env.REACT_APP_SHOW_INSURANCE &&
    /true/.test(process.env.REACT_APP_SHOW_INSURANCE);

  return (
    <Container className="mt-4 account-settings-container">
      <Row>
        <Col md={4} lg={4} xl={3} className="hide-on-mobile hide-on-tablet">
          <AccountPagesSidebar />
        </Col>
        <Col md={8} lg={8} xl={9} className="auto-margin">
          {/* {enableInsurance && <AccountInsuranceItems  title={"Insurance Claims"}   claims={quotallClaims} dataLoaded={quotallClaimsLoaded}/>} */}
          <AccountItemList
            items={(publishedItems && publishedItems.data) ?? []}
            title={"Published Items"}
            dataLoaded={publishedItemsLoaded}
            sort={sortPublished}
            setSort={setSortPublished}
          />
          <div className="show-more-div">
            {publishedItemsLoaded && publishedItems && publishedItems.nextPage && (
              <Button
                onClick={onLoadMorePublished}
                className="auto-margin width-120"
              >
                {showMorePublishedClicked ? (
                  <SmallLoadingSpinner />
                ) : (
                  t("show_more")
                )}
              </Button>
            )}
          </div>
          <AccountItemList
            items={(unpublishedItems && unpublishedItems.data) ?? []}
            title={"Unpublished Items"}
            dataLoaded={unpublishedItemsLoaded}
            sort={sortUnpublished}
            setSort={setSortUnpublished}
          />
          <div className="show-more-div">
            {unpublishedItemsLoaded &&
              unpublishedItems &&
              unpublishedItems.nextPage && (
                <Button
                  onClick={onLoadMoreUnpublished}
                  className="auto-margin width-120"
                >
                  {showMoreUnpublishedClicked ? (
                    <SmallLoadingSpinner />
                  ) : (
                    t("show_more")
                  )}
                </Button>
              )}
          </div>
        </Col>
      </Row>
    </Container>
  );
}
