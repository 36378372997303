export function condenseItemForList(item) {
  return {
    id: item.id,
    image: {
      path: item.images ? item.images[0]?.path : "",
    },
    url: item.url,
    itemName: item.name,
    dayRate: item.priceInfo.cheapestDailyPrice,
    rating: item.user.reviewValueAverage,
    ownerName: item.user.firstName,
    ownerId: item.user.id,
    locationName: item.location?.city || "",
    ownerProfilePicture: {
      path: item.user.profileImage?.path,
    },
  };
}
