import { useEnv } from "../../context/env.context";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Navbar from "./nav-menu/Navbar";

interface LayoutProps {
  children: React.ReactNode;
}
export const Layout = ({ children }: LayoutProps) => {
  const [hamburgerMenuOpen, setHamburgerMenuOpen] = useState(false);

  const [isMobile, setIsMobile] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  function handleWindowSizeChange() {
    setIsMobile(window.innerWidth <= 920);
    setIsSmallScreen(window.innerWidth <= 1257);
  }

  useEffect(() => {
    handleWindowSizeChange();
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const { theme } = useEnv();
  const navigate = useNavigate();
  return (
    <div id="layout-container" className={`layout-container theme-${theme}`}>
      <div className="navbar-header-container">
        <img
          className="mobile-navbar-image"
          src="/assets/img/RentMy-logo-no-tm.webp"
          onClick={() => navigate("/")}
          alt="RentMy Logo"
        />
        <Navbar
          hamburgerMenuOpen={hamburgerMenuOpen}
          setHamburgerMenuOpen={setHamburgerMenuOpen}
          isMobile={isMobile}
          isSmallScreen={isSmallScreen}
        />
      </div>
      <div className="content-container">{children}</div>
    </div>
  );
};
