import { useEffect, useState, useRef, useCallback } from "react";
import _ from "lodash";
export function useScrollPosition() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const scrollWrapperRef: any = useRef();

  let handleScroll = useCallback(
    _.debounce(() => {
      const position = scrollWrapperRef.current.scrollTop;
      setScrollPosition(position);
    }, 100),
    []
  );
  useEffect(() => {
    scrollWrapperRef.current = document.getElementById("root");
    scrollWrapperRef.current?.addEventListener("scroll", handleScroll);
    return () => {
      scrollWrapperRef.current?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return scrollPosition;
}
