import * as React from "react";
import { useEffect, useState, useRef } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Pagination, Navigation } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

export default function ItemCarousel({ item }) {
  return item ? (
    <div className="no-gutter-wrapper">
      <Swiper
        className="single-item-images-carousel"
        spaceBetween={20}
        slidesPerView={1}
        pagination={true}
        navigation
        centeredSlides={true}
        modules={[Navigation, Pagination]}
      >
        {item.images?.map((image) => {
          return (
            <SwiperSlide key={image.id}>
              <div
                className="slide"
                style={{
                  backgroundImage: `url('${decodeURI(image.path)}')`,
                }}
              ></div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  ) : null;
}
