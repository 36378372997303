import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ItemSearchDto } from "../../api/rentMyApi";
import { dayRateDisplay } from "../../utils/dayRateDisplay";
import { useWindowSize } from "../../utils/WindowSize";
type ItemHighlightProps = {
  item: ItemSearchDto;
  isFocused?: boolean;
  isItemSearch?: boolean;
  isAlreadyOnItemPage?: boolean;
};

export default function ItemHighlight({
  item,
  isFocused = false,
}: ItemHighlightProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const itemImageURL =
    item.image && item.image.path
      ? item.image.path
      : "/assets/img/placeholder.jpg";

    const { width } = useWindowSize();
    const isMobileView = width <= 768;
    
   return (
    <>
      {item && (
          <div
              key={item.id}
              className={`single-item-container ${isFocused ? "focused" : ""}`}
          >
            <div onClick={() => {  window.open(`/item/${item.url}`, isMobileView ? "_self" : "_blank") }}>
                <div className="item-image" style={{backgroundImage: `url('${itemImageURL}')`}}>
                    <img
                        src={itemImageURL}
                        className="item-image-img"
                        alt={item.itemName}
                    />
                </div>
                <div className="item-meta">
                  <div>
                    <h5 className="mb-0">
                      {item.itemName}
                    </h5>
                    <div className="item-price-rating">
                        <span className="item-pd-price">
                            {
                                item.dayRate && (
                                    <>
                                        £{dayRateDisplay(item.dayRate)}<span>/{t("search_results_price_day")}</span>
                                    </>
                                )
                            }
                        </span>
                        {
                            item.rating && item.reviewCount > 0 && (
                                <span className={`item-star-rating ${Math.round(item.rating) === 5 ? 'highlight' : ''}`}>
                                    <FontAwesomeIcon icon={faStar}/>
                                    <span>{Math.round(item.rating)}</span>
                                </span>
                            )
                        }
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-owner" onClick={() => navigate("/public/profile?id=" + item.ownerId)}>
                    {
                        item.ownerProfilePicture && item.ownerProfilePicture.path && (
                            <img
                                alt={`${item.ownerName} avatar`}
                                src={item.ownerProfilePicture.path}
                                className="item-owner-avatar"
                        />
                        )
                    }
                    <span>{item.ownerName} {item.locationName ? `in ${item.locationName}` : ""}</span>
            </div>
          </div>
        )}
    </>
  );
}
